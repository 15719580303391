// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/ruucm/MyDocuments/Carving/projects/harborschool-gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cl-test-page-js": () => import("/Users/ruucm/MyDocuments/Carving/projects/harborschool-gatsby/src/pages/cl-test-page.js" /* webpackChunkName: "component---src-pages-cl-test-page-js" */),
  "component---src-pages-framer-korea-meetup-js": () => import("/Users/ruucm/MyDocuments/Carving/projects/harborschool-gatsby/src/pages/framer-korea-meetup.js" /* webpackChunkName: "component---src-pages-framer-korea-meetup-js" */),
  "component---src-pages-framerx-webinar-js": () => import("/Users/ruucm/MyDocuments/Carving/projects/harborschool-gatsby/src/pages/framerx-webinar.js" /* webpackChunkName: "component---src-pages-framerx-webinar-js" */),
  "component---src-pages-hello-framer-x-js": () => import("/Users/ruucm/MyDocuments/Carving/projects/harborschool-gatsby/src/pages/hello-framer-x.js" /* webpackChunkName: "component---src-pages-hello-framer-x-js" */),
  "component---src-pages-index-js": () => import("/Users/ruucm/MyDocuments/Carving/projects/harborschool-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-installment-payment-js": () => import("/Users/ruucm/MyDocuments/Carving/projects/harborschool-gatsby/src/pages/installment-payment.js" /* webpackChunkName: "component---src-pages-installment-payment-js" */),
  "component---src-pages-mail-template-js": () => import("/Users/ruucm/MyDocuments/Carving/projects/harborschool-gatsby/src/pages/mail-template.js" /* webpackChunkName: "component---src-pages-mail-template-js" */),
  "component---src-pages-manual-payment-js": () => import("/Users/ruucm/MyDocuments/Carving/projects/harborschool-gatsby/src/pages/manual-payment.js" /* webpackChunkName: "component---src-pages-manual-payment-js" */),
  "component---src-pages-my-page-js": () => import("/Users/ruucm/MyDocuments/Carving/projects/harborschool-gatsby/src/pages/my-page.js" /* webpackChunkName: "component---src-pages-my-page-js" */),
  "component---src-pages-order-complete-js": () => import("/Users/ruucm/MyDocuments/Carving/projects/harborschool-gatsby/src/pages/order-complete.js" /* webpackChunkName: "component---src-pages-order-complete-js" */)
}

